import {useHistory} from "react-router-dom";
import {useEffect} from "react";

const DefaultIndex = () => {
    const history = useHistory();
    useEffect(() => {
        const searchParams = new Map<string, string>(window.location.search.substring(1).split('&').map(s => s.split('=') as [string, string]));
        if (window.location.href.includes('?token=')) {
            const token = searchParams.get('token');

            if (token) {
                sessionStorage.setItem("__access_token__", token);

            }
            // location.href = redirect;
        } else {
            window.location.href = "/login";
            // history.push("/login");
        }
    }, []);

    return <>잠시만 기다려주세요...</>
}

export default DefaultIndex;
