import React, {lazy, Suspense} from "react";
import {Route, Switch} from "react-router-dom";

const Login = lazy(() => import("./screen/Login"));
const SignUp = lazy(() => import("./screen/SignUp"));
const ResetPassword = lazy(() => import("./screen/ResetPassword"));
const Main = lazy(() => import("./screen/Main"));
const Terms = lazy(() => import("./screen/ect/Terms"));
const Privacy = lazy(() => import("./screen/ect/Privacy"));
const Agreement = lazy(() => import("./screen/ect/PrivacyAgree"));
export const RouteLoading = () => <>Loading...</>;
export default function AppRoutes() {
    return <div style={{
        height: '100vh',
        maxWidth: '100%',
    }}>
        {/*<ErrorBoundary sendToNative={(msg) => sendMessageToNative({eventMsg: WEB_APP_EVENT.TEST_LOG, eventData: msg})}>*/}
        <Suspense
            fallback={<RouteLoading/>}
        >
            <Switch>
                <Route path="/login" component={Login}/>
                <Route path="/resetpw" component={ResetPassword}/>
                <Route path="/signup" component={SignUp}/>
                <Route path="/main" component={Main}/>
                <Route path="/terms" component={Terms}/>
                <Route path="/privacy" component={Privacy}/>
                <Route path="/agreement" component={Agreement}/>
            </Switch>
        </Suspense>
        {/*</ErrorBoundary>*/}
    </div>
}
