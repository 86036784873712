import React, {
    createContext,
    forwardRef,
    PropsWithChildren,
    ReactNode,
    Ref,
    Suspense,
    useCallback,
    useContext,
    useEffect,
    useImperativeHandle,
    useMemo,
    useRef,
    useState
} from 'react';
let elementId = 1;
export default function useModal({exitOnUnmount = true}: { exitOnUnmount?: boolean; } = {}) {
    const context = useContext(ModalContext);
    if (context == null) {
        throw new Error('<ModalProvider> is not found');
    }

    const {mount, unmount} = context;
    const [id] = useState(() => {
        return String(elementId++)
    });

    const [modalVisible, setModalVisible] = useState<boolean>(false);
    const [modalStack, setModalStack] = useState<AsyncCreateModalElement<any>[]>([]);
    const modalRef = useRef<ModalControlRef | null>(null);

    useEffect(() => {
        return () => {
            if (exitOnUnmount) {
                unmount(id);
            }
        };
    }, [exitOnUnmount, id, unmount]);

    useEffect(() => {
        if (!modalVisible && modalStack.length > 0) {
            setModalVisible(() => true);
            new Promise<any>(res => {
                mount(
                    id,
                    <AsyncModalController
                        key={Date.now()}
                        ref={modalRef}
                        modalElement={modalStack[0]}
                        resolve={() => {
                            const [_, ...otherStacks] = modalStack;
                            setModalStack(otherStacks);
                        }}
                        afterClose={() => {
                            setModalVisible(() => false);
                            unmount(id);
                        }}
                    />
                );
            })
        }
    }, [modalVisible, modalStack])

    function asyncOpen<T>(modalElement: AsyncCreateModalElement<T>) {
        setModalStack((pv) => [...pv, modalElement]);

        // return new Promise<T>(res => {
        //     mount(
        //         id,
        //         <AsyncModalController
        //             key={Date.now()}
        //             ref={modalRef}
        //             modalElement={modalElement}
        //             resolve={res as (v: unknown) => void}
        //             afterClose={() => {
        //                 unmount(id);
        //             }}
        //         />
        //     );
        // })
    }

    return useMemo(
        () => ({
            asyncOpen,
            clear: () => {
                setModalStack(() => [])
                modalRef.current?.close();
            },
            close: () => {
                modalRef.current?.close();
            },
        }),
        [id, mount, unmount]
    );
}

export const AsyncModalController = forwardRef(function AsyncModalController<T>(
    {
        modalElement: ModalElement,
        afterClose,
        resolve
    }: {
        modalElement: AsyncCreateModalElement<T>;
        afterClose: () => void;
        resolve: (value: T | null) => void;
    },
    ref: Ref<ModalControlRef>
) {
    const [isOpen, setIsOpen] = useState(false);

    const handleClose = useCallback(() => {
        resolve(null);
        afterClose();
        setIsOpen(false);
    }, []);

    const handleOk = useCallback((value: T) => {
        resolve(value);
        setIsOpen(false);
    }, []);

    useImperativeHandle(
        ref,
        () => {
            return {close: handleClose};
        },
        [handleClose]
    );

    useEffect(() => {
        // NOTE: requestAnimationFrame이 없으면 가끔 Open 애니메이션이 실행되지 않는다.
        requestAnimationFrame(() => {
            setIsOpen(true);
        });
    }, []);

    return <Suspense>
        <ModalElement open={isOpen}
                      onClose={handleClose}
                      onCancel={handleClose}
                      onOk={handleOk}
                      afterClose={afterClose}
        />
    </Suspense>;
})

export const ModalContext = createContext<{
    mount(id: string, element: ReactNode): void;
    unmount(id: string): void;
} | null>(null);

export function ModalProvider({children}: PropsWithChildren<{ containerId?: string }>) {
    const [modalById, setModalById] = useState<Map<string, ReactNode>>(new Map());

    const mount = useCallback((id: string, element: ReactNode) => {
        setModalById(modalById => {
            const cloned = new Map(modalById);
            cloned.set(id, element);
            return cloned;
        });
    }, [modalById]);

    const unmount = useCallback((id: string) => {
        setModalById(modalById => {
            const cloned = new Map(modalById);
            cloned.delete(id);
            return cloned;
        });
    }, []);

    const context = useMemo(() => ({mount, unmount}), [mount, unmount]);

    return (
        <ModalContext.Provider value={context}>
            {children}
            {[...modalById.entries()].map(([id, element]) => (
                <React.Fragment key={id}>{element}</React.Fragment>
            ))}
        </ModalContext.Provider>
    );
}
export interface ModalControlRef {
    close: () => void;
}

export type AsyncCreateModalElement<T> = (props: {
    open: boolean;
    onOk: (result: T) => void;
    onClose: () => void;
    onCancel: () => void;
    afterClose: () => void
}) => JSX.Element;