import React from 'react';
import logo from './logo.svg';
// import './App.css';
import {BrowserRouter as Router, Route, Switch} from 'react-router-dom';
import {QueryClient, QueryClientProvider} from "react-query";
import AppRoutes from "./AppRoutes";
import DefaultIndex from "./screen/DefaultIndex";
import {ModalProvider} from "./hooks/useModal";

const queryClient = new QueryClient({defaultOptions: {queries: {staleTime: 60_000, refetchOnWindowFocus: false}}});

function App() {
    return (
        <QueryClientProvider client={queryClient}>
            <ModalProvider>
                <Router>
                    <Switch>
                        <Route exact path="/" component={DefaultIndex}/>
                        <AppRoutes/>
                    </Switch>
                </Router>
            </ModalProvider>
        </QueryClientProvider>
    );
}

export default App;
